<template>
  <div class="custom-select">
    <div
      class="custom-select__wrapper"
      ref="select"
      :class="{ 'open-up': openUp }"
    >
      <div class="selected-items" @click="toggleDropdown">
        <div class="selected-items__wrapper">
          <span
            v-for="(item, index) in selectedItems"
            :key="index"
            class="selected-item"
          >
            {{ item.name }}
            <span class="remove-item" @click.stop="removeItem(item)">x</span>
          </span>

          <div v-if="selectedItems.length === 0" class="select-placeholder">
            <span>Выберите...</span>
          </div>
        </div>

        <img
          src="@/assets/icon/chevron_down.svg"
          :class="{ 'rotate-icon': isOpen }"
        />
      </div>

      <transition name="fade">
        <div class="dropdown" v-if="isOpen">
          <ul class="dropdown-list">
            <li
              v-for="option in options"
              :key="option.id"
              @click="selectItem(option)"
              :class="{ 'is-selected': isSelected(option) }"
            >
              {{ option.name }}
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <rq-btn
      class="clear-button"
      v-if="selectedItems.length > 0"
      @click="clearSelection"
      >Очистить выбор</rq-btn
    >
  </div>
</template>

<script>
import RqBtn from '@/components/ui/RqButton.vue'
export default {
  name: 'CustomSelect',
  components: {
    RqBtn,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  data() {
    return {
      selectedItems: [],
      isOpen: false,
      openUp: false,
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.calculateDropdownDirection()
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    handleClickOutside(event) {
      const selectElement = this.$refs.select
      if (!selectElement.contains(event.target)) {
        this.isOpen = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    selectItem(option) {
      const index = this.selectedItems.findIndex(
        (item) => item.id === option.id
      )
      if (index === -1) {
        this.selectedItems.push(option)
        this.$emit('update', this.selectedItems)
      } else {
        this.selectedItems.splice(index, 1)
        this.$emit('update', this.selectedItems)
      }
    },
    isSelected(option) {
      return this.selectedItems.some((item) => item.id === option.id)
    },
    removeItem(item) {
      this.selectedItems = this.selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id
      )
      this.$emit('update', this.selectedItems)
    },
    clearSelection() {
      this.selectedItems = []
    },
    calculateDropdownDirection() {
      const selectElement = this.$refs.select
      const bounding = selectElement.getBoundingClientRect()
      const spaceBelow = window.innerHeight - bounding.bottom
      const dropdownHeight = 200

      if (spaceBelow < dropdownHeight && bounding.top > dropdownHeight) {
        this.openUp = true
      } else {
        this.openUp = false
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.calculateDropdownDirection)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateDropdownDirection)
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.custom-select__wrapper {
  position: relative;
  max-width: 300px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ccc;
  color: $grey_dark;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.selected-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.selected-items__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.selected-item {
  background-color: $blue;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.remove-item {
  margin-left: 5px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  left: -1px;
  width: 100%;
  top: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.open-up .dropdown {
  bottom: 100%;
  top: auto;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dropdown-list li:hover {
  background-color: #f1f1f1;
  color: #2f3341;
}

.is-selected {
  background-color: $blue;
  color: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.open-up .fade-enter-from,
.open-up .fade-leave-to {
  transform: translateY(10px);
}

.select-placeholder img {
  transition: transform 0.3s ease;
}

.select-placeholder img.rotate-icon {
  transform: rotate(180deg);
}

.clear-button {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: $red;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.clear-button:hover {
  background-color: darken($red, 10%);
}
</style>
