<template>
  <div class="graph-page">
    <div class="graph-page__chart-section main-container">
      <Chart :labels="labels" :dates="dates" />
    </div>
    <div class="graph-page__form-section main-container">
      <div class="graph-form">
        <h2 class="graph-form__title mb_16">
          {{ isEditMode ? 'Редактировать график' : 'Создать новый график' }}
        </h2>

        <form
          @submit.prevent="onSubmit"
          @keydown.enter="onSubmit"
          class="graph-form__form"
        >
          <div class="graph-form__group">
            <label class="graph-form__label">Группа графика</label>
            <rq-select-simple
              class="graph-form__select"
              v-model="form.group"
              :class="{ 'graph-form__select-group--error': errors.group }"
              :options="groups"
              @update:modelValue="validateEmpty('group')"
            />
            <span class="graph-form__error-message">{{ errors.group }}</span>

            <div class="graph-form__group-wrapper">
              <span class="graph-form__group-text">
                Если нет нужной группы, вы можете добавить:
              </span>
              <button
                class="graph-form__group-button button"
                @click="addGroup"
                type="button"
              >
                Добавить новую группу
              </button>
            </div>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="graphName"
              >Название графика</label
            >
            <input
              class="graph-form__input"
              :class="{ 'graph-form__input--error': errors.graphName }"
              type="text"
              id="graphName"
              :value="form.graphName"
              @input="validateTextField($event.target.value, 'graphName')"
              @blur="validateTextField($event.target.value, 'graphName')"
            />
            <span class="graph-form__error-message">{{
              errors.graphName
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="tableName"
              >Название таблицы в базе данных</label
            >
            <input
              class="graph-form__input"
              :class="{ 'graph-form__input--error': errors.tableName }"
              type="text"
              id="tableName"
              :value="form.tableName"
              @input="validateLatinLetters($event.target.value, 'tableName')"
              @blur="validateLatinLetters($event.target.value, 'tableName')"
            />
            <span class="graph-form__error-message">{{
              errors.tableName
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="url">URL</label>
            <input
              class="graph-form__input"
              :class="{ 'graph-form__input--error': errors.url }"
              type="url"
              id="url"
              :value="form.url"
              @input="validateUrl($event.target.value, 'url')"
              @blur="validateUrl($event.target.value, 'url')"
            />
            <span class="graph-form__error-message">{{ errors.url }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="upperLimit"
              >Отсечка сверху</label
            >
            <input
              class="graph-form__input graph-form__input--number"
              :class="{ 'graph-form__input--error': errors.upperLimit }"
              type="number"
              id="upperLimit"
              :value="form.upperLimit"
              @input="validateNumberField($event.target.value, 'upperLimit')"
              @blur="validateNumberField($event.target.value, 'upperLimit')"
            />
            <span class="graph-form__error-message">{{
              errors.upperLimit
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="lowerLimit"
              >Отсечка снизу</label
            >
            <input
              class="graph-form__input graph-form__input--number"
              :class="{ 'graph-form__input--error': errors.lowerLimit }"
              type="number"
              id="lowerLimit"
              :value="form.lowerLimit"
              @input="validateNumberField($event.target.value, 'lowerLimit')"
              @blur="validateNumberField($event.target.value, 'lowerLimit')"
            />
            <span class="graph-form__error-message">{{
              errors.lowerLimit
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="pollingFrequency"
              >Частота опроса в минутах</label
            >
            <input
              class="graph-form__input graph-form__input--number"
              :class="{ 'graph-form__input--error': errors.pollingFrequency }"
              type="number"
              id="pollingFrequency"
              :value="form.pollingFrequency"
              @input="
                validateNumberField($event.target.value, 'pollingFrequency')
              "
              @blur="
                validateNumberField($event.target.value, 'pollingFrequency')
              "
            />
            <span class="graph-form__error-message">{{
              errors.pollingFrequency
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="order"
              >Порядок следования</label
            >
            <input
              class="graph-form__input graph-form__input--number"
              :class="{ 'graph-form__input--error': errors.order }"
              type="number"
              id="order"
              :value="form.order"
              @input="validateNumberField($event.target.value, 'order')"
              @blur="validateNumberField($event.target.value, 'order')"
            />
            <span class="graph-form__error-message">{{ errors.order }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="errorThreshold"
              >Кол-во ошибок подряд для триггера уведомления</label
            >
            <input
              class="graph-form__input graph-form__input--number"
              :class="{ 'graph-form__input--error': errors.errorThreshold }"
              type="number"
              id="errorThreshold"
              :value="form.errorThreshold"
              @input="
                validateNumberField($event.target.value, 'errorThreshold')
              "
              @blur="validateNumberField($event.target.value, 'errorThreshold')"
            />
            <span class="graph-form__error-message">{{
              errors.errorThreshold
            }}</span>
          </div>

          <div class="graph-form__group">
            <label class="graph-form__label" for="accessGroups"
              >Группы доступов</label
            >
            <rq-select-multiple
              class="graph-form__select"
              :class="{ 'graph-form__select--error': errors.accessGroups }"
              :options="accessGroups"
              @update="updateSelectedValue"
            />
            <span class="graph-form__error-message">{{
              errors.accessGroups
            }}</span>
          </div>

          <rq-btn class="graph-form__submit" type="submit">
            {{ isEditMode ? 'Сохранить' : 'Создать' }}
          </rq-btn>
        </form>
      </div>
    </div>
  </div>

  <popup
    class="new-group-popup"
    :show="showPopup"
    @update:show="showPopup = $event"
  >
    <h2 class="new-group-popup__title">Добавить новую группу</h2>
    <p class="new-group-popup__description">Введите название новой группы:</p>
    <input
      class="new-group-popup__input"
      :class="{ 'new-group-popup__input--error': errors.newGroupName }"
      type="text"
      v-model="newGroupName"
      @blur="validateLatinLetters($event.target.value, 'newGroupName')"
      required
    />
    <span class="new-group-popup__error-message">{{
      errors.newGroupName
    }}</span>
    <rq-btn
      class="new-group-popup__button"
      :disabled="errors.newGroupName || !newGroupName"
      type="button"
      @click="confirmAddGroup"
    >
      Добавить
    </rq-btn>
  </popup>
</template>

<script>
import Chart from '@/components/Chart.vue'
import RqBtn from '@/components/ui/RqButton.vue'
import RqSelectMultiple from '@/components/ui/RqSelectMultiple.vue'
import RqSelectSimple from '@/components/ui/RqSelectSimple.vue'
import Popup from '@/components/ui/Popup.vue'

export default {
  components: {
    Popup,
    RqBtn,
    Chart,
    RqSelectMultiple,
    RqSelectSimple,
  },
  data() {
    return {
      isEditMode: false,
      form: {
        group: null,
        graphName: '',
        tableName: '',
        upperLimit: null,
        lowerLimit: null,
        url: '',
        pollingFrequency: null,
        errorThreshold: null,
        order: null,
        accessGroups: null,
      },
      labels: [
        'January',
        'February',
        'March',
        'January',
        'February',
        'March',
        'January',
        'February',
        'March',
        'February',
        'March',
        'January',
        'February',
        'March',
      ],
      dates: [10, 20, 12, 32, 20, 12, 10, 20, 12, 20, 12, 42, 20, 45],
      groups: [
        { id: 1, name: 'Frontend' },
        { id: 2, name: 'Backend' },
      ],
      accessGroups: [
        { id: 1, name: 'op' },
        { id: 2, name: 'io' },
        { id: 3, name: 'admin' },
        { id: 4, name: 'manager' },
        { id: 5, name: 'develop' },
        { id: 6, name: 'all' },
      ],
      showPopup: false,
      newGroupName: '',
      errors: {},
    }
  },

  methods: {
    validateTextField(value, field) {
      this.form.graphName = value
      const regex = /^[а-яА-Яa-zA-Z-]+$/
      let error = ''

      if (!this.form[field]) {
        error = 'Обязательно для заполнения.'
      } else if (!regex.test(this.form[field])) {
        error = 'Поле может содержать только буквы и тире.'
      }

      this.errors[field] = error
    },

    validateLatinLetters(value, field) {
      this.form.tableName = value
      const regex = /^[a-zA-Z_]+$/
      const valueField =
        field === 'newGroupName' ? this.newGroupName : this.form[field]
      let error = ''

      if (!valueField) {
        error = 'Обязательно для заполнения.'
      } else if (!regex.test(valueField)) {
        error =
          'Поле может содержать только английские буквы и нижнее подчеркивание.'
      }

      this.errors[field] = error
    },

    validateUrl(value, field) {
      this.form.url = value
      const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
      let error = ''

      if (!this.form[field]) {
        error = 'Обязательно для заполнения.'
      } else if (!regex.test(this.form[field])) {
        error = 'Введите корректный URL.'
      }

      this.errors.url = error
    },

    validateEmpty(field) {
      if (
        !this.form[field] ||
        (Array.isArray(this.form[field]) && !this.form[field].length)
      ) {
        this.errors[field] = `Обязательно для заполнения.`
      } else {
        this.errors[field] = ''
      }
    },

    validateNumberField(value, field) {
      this.form[field] = value
      if (!this.form[field]) {
        this.errors[field] = `Обязательно для заполнения.`
      } else if (isNaN(this.form[field])) {
        this.errors[field] = `Значение должно быть числом.`
      } else {
        this.errors[field] = ''
      }
    },

    updateSelectedValue(value) {
      this.form.accessGroups = value
      this.validateEmpty('accessGroups')
    },

    onSubmit() {
      this.validateTextField(this.form.graphName, 'graphName')
      this.validateLatinLetters(this.form.tableName, 'tableName')
      this.validateUrl(this.form.url, 'url')
      this.validateEmpty('group')
      this.validateEmpty('accessGroups')
      this.validateNumberField(this.form.upperLimit, 'upperLimit')
      this.validateNumberField(this.form.lowerLimit, 'lowerLimit')
      this.validateNumberField(this.form.pollingFrequency, 'pollingFrequency')
      this.validateNumberField(this.form.errorThreshold, 'errorThreshold')
      this.validateNumberField(this.form.order, 'order')

      if (!Object.values(this.errors).some((error) => error)) {
        if (this.isEditMode) {
          console.log('График обновлен:', this.form)
        } else {
          console.log('График создан:', this.form)
        }
      }
    },

    addGroup() {
      this.showPopup = true
    },

    confirmAddGroup() {
      if (this.newGroupName && !this.errors.newGroupName) {
        const newGroupId = this.groups.length + 1
        this.groups.push({ id: newGroupId, name: this.newGroupName })
        this.form.group = newGroupId
        this.newGroupName = ''
        this.showPopup = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';
.graph_page .graph-page__chart-section {
  padding-top: 30px;
  max-height: 50vh;
  margin-bottom: 40px;
}

.graph-page__form-section {
  padding: 30px 15px;
}

.graph-form__group {
  margin-bottom: 8px;
}

.graph-form__label {
  display: block;
  margin-bottom: 8px;
}

.graph-form__input,
.new-group-popup__input {
  padding: 5px 10px;
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
  outline: none;
  font-size: 18px;
  transition: all 0.3s ease;
}

.graph-form__input[type='number'] {
  max-width: 150px;
}

.graph-form__input--error,
.new-group-popup__input--error,
.graph-form__select-group--error {
  border-color: $red;
  background: $red_extra_light;
}

.graph-form__select--error:deep .custom-select__wrapper {
  border-color: $red;
  background: $red_extra_light;
}

.graph-form__error-message,
.new-group-popup__error-message {
  color: $red;
  font-size: 15px;
  margin-top: 8px;
  display: block;
  height: 17px;
}

.graph-form__group-text {
  margin-right: 8px;
  display: inline-block;
}

.graph-form__group-button,
.graph-form__submit,
.new-group-popup__button {
  padding: 5px 10px;
  color: $grey_dark;
}

.graph-form__group-button {
  cursor: pointer;
  position: relative;
  text-align: center;
  font-size: 18px;
  border: none;
  border-radius: 7px;
  transition: all 0.1s ease-in 0s;
}

.error-message {
  color: $red;
  font-size: 15px;
  margin-top: 8px;
  display: block;
}

.new-group-popup__title,
.new-group-popup__description {
  margin-bottom: 16px;
  display: block;
}

.new-group-popup__button {
  margin-top: 8px;
}
</style>
