<template>
  <Line :data="data" :options="mergedOptions" height="50vh" />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js'

import { Line } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip)

const getGradient = (ctx, chartArea) => {
  let width, height, gradient
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(0, '#3a61b9')
    gradient.addColorStop(1, '#e3135e')
  }
  return gradient
}

export default {
  name: 'Chart',
  components: { Line },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    dates: {
      type: Array,
      default: () => [],
    },
    customOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mergedOptions() {
      return {
        ...this.options,
        ...this.customOptions,
      }
    },
  },
  data() {
    return {
      data: {
        labels: this.labels,
        datasets: [
          {
            label: 'Errors',
            fill: false,
            lineTension: 0.1,
            borderColor: function (context) {
              const chart = context.chart
              const { ctx, chartArea } = chart
              if (!chartArea) {
                // This case happens on initial chart load
                return
              }
              return getGradient(ctx, chartArea)
            },
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            backgroundColor: '#e3135e',
            pointBorderColor: '#e3135e',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: '#e3135e',
            pointHoverBorderColor: '#e3135e',
            pointHoverBorderWidth: 5,
            pointRadius: 0,
            pointHitRadius: 3,
            spanGaps: false,
            data: this.dates,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            usePointStyle: true,
          },
        },
      },
    }
  },
  mounted() {},
  watch: {
    $route() {},
  },
  methods: {
    open() {
      this.$emit('open', this.item.id)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
.error_popup_container {
  min-width: 800px;
  min-height: 70vh;
  position: relative;
  .loading_container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s;
    &.visible {
      opacity: 1;
    }
  }
  .content_container {
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s;
    &.visible {
      opacity: 1;
    }
  }
  @media (max-width: 930px) {
    min-width: 500px;
  }
  @media (max-width: 610px) {
    min-width: 300px;
  }
}
</style>
