<template>
  <div class="custom-select" ref="select" :class="{ 'open-up': openUp }">
    <div class="selected-items" @click="toggleDropdown">
      <span v-if="selectedItem">{{ selectedItem.name }}</span>
      <div v-else class="select-placeholder">
        <span>Выберите...</span>
      </div>
      <img
        src="@/assets/icon/chevron_down.svg"
        :class="{ 'rotate-icon': isOpen }"
      />
    </div>
    <transition name="fade">
      <div class="dropdown" v-if="isOpen">
        <ul class="dropdown-list">
          <li
            v-for="option in options"
            :key="option.id"
            @click="selectItem(option)"
            :class="{ 'is-selected': isSelected(option) }"
          >
            {{ option.name }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
      isOpen: false,
      openUp: false,
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.calculateDropdownDirection()
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    handleClickOutside(event) {
      const selectElement = this.$refs.select
      if (!selectElement.contains(event.target)) {
        this.isOpen = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    selectItem(option) {
      this.selectedItem = option
      this.$emit('update:modelValue', option)
      this.isOpen = false
    },
    isSelected(option) {
      return this.selectedItem && this.selectedItem.id === option.id
    },
    calculateDropdownDirection() {
      const selectElement = this.$refs.select
      const bounding = selectElement.getBoundingClientRect()
      const spaceBelow = window.innerHeight - bounding.bottom
      const dropdownHeight = 200

      if (spaceBelow < dropdownHeight && bounding.top > dropdownHeight) {
        this.openUp = true
      } else {
        this.openUp = false
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.calculateDropdownDirection)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateDropdownDirection)
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.custom-select {
  position: relative;
  max-width: 300px;
  width: 100%;
  background: #ffffff;
  border: 1px solid $grey_light;
  color: $grey_dark;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.selected-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown {
  position: absolute;
  left: -1px;
  top: 100%;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.open-up .dropdown {
  bottom: 100%;
  top: auto;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dropdown-list li:hover {
  background-color: #f1f1f1;
  color: #2f3341;
}

.is-selected {
  background-color: $blue;
  color: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.open-up .fade-enter-from,
.open-up .fade-leave-to {
  transform: translateY(10px);
}

.select-placeholder {
  display: flex;
  align-items: center;
}

.selected-items img {
  transition: transform 0.3s ease;
}

.selected-items img.rotate-icon {
  transform: rotate(180deg);
}
</style>
